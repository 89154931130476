import './App.css';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import { ContextProvider } from './state/all';
import RequireAuth from './RequireAuth';
import loadable from '@loadable/component';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login, ForgotPassword, ResetPassword } from './auth';
import Loader from './components/loader';

const AsyncDashboardLayout = loadable(() => import(/* webpackChunkName: "dashboard-layout", webpackPrefetch: true */ '../src/dashboard/layout/index'), {
  fallback: <Loader />,
});

const App = () => {

  return (
    <>
      <BrowserRouter>
        <ContextProvider>
          <Routes>
            <Route path="/" element={<RequireAuth><Navigate to="/dashboard" /> </RequireAuth>} />
            <Route exact path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard/*" element={<RequireAuth><AsyncDashboardLayout /> </RequireAuth>} />
          </Routes>
          <ToastContainer />
        </ContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
