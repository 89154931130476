import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Form, Input } from "antd";
import { SendOutlined, StepBackwardFilled } from '@ant-design/icons';
import { RequestPasswordResetMutation } from "../services/mutations";
import { isValidEmail } from "../components/helpers";
import { successToast, errorToast, formatError } from '../components/utils';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [visible, setVisible] = useState(true);
    const [errorEmail, setErrorEmail] = useState('')
    const [apiError, setApiError] = useState('');
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const API_URL = process.env.REACT_APP_API_URL;

    const handeChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        if(email) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    
    }, [email]); 

    const handleSubmit = (e) => {
        e.preventDefault();
        setApiError('');
        setLoading(true);
        if (email === '') {
            errorToast('Please enter email and password');
            setError(true);
            setErrorEmail('Please enter a valid email address');
            setLoading(false);
            return;
        };
        if (isValidEmail(email) === false) {
            setError(true);
            setErrorEmail('Please enter a valid email address');
            setLoading(false);
            return;
        };
        axios.post(API_URL, {
            query: RequestPasswordResetMutation,
            variables: {
                "email": email, 
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
         })
        .then((response) => {
            console.log(response.data)
            successToast('Password reset link sent successfully');
            handleGotoLogin();
        })
        .catch((error) => {
            setLoading(false);
            setLoading(false);
            setError(true);
            setApiError('Error occurred during login');
            errorToast(apiError)
            console.error('Error:', error);
        });
    };

    const handleGotoLogin = () => {
        setLoading(false);
        return navigate('/login');
    }
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="gap-5">
                <h1 className="text-3xl font-bold p-10">Forgot Password</h1>
            </div>
            <div className="card bg-base-100 rounded-md w-full max-w-sm shrink-0 shadow-2xl">
                <div className="card-body p-10">
                    <Form 
                        form={form}
                        layout="vertical" 
                        autoComplete="off"
                    >
                        <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                            required: true,
                            message: 'Please input your Email!',
                            },
                        ]}
                        >
                            <Input type="text" 
                                className="grow" 
                                placeholder="Email"
                                onChange={(e) => handeChangeEmail(e)} />
                        </Form.Item>
                        {(error)?<div className="text-center text-error">{errorEmail}</div>: null}

                        <div className="flex space-x-12 items-center m-5 pr-5 pl-5">
                            <div className="form-control">
                                <Button type="primary" danger icon={<StepBackwardFilled />} onClick={()=>handleGotoLogin()}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="form-control">
                                <Form.Item style={{ padding: 0, margin: 0}}>
                                    {(loading === false)?
                                    (<Button 
                                        type="primary" 
                                        htmlType="submit" 
                                        icon={<SendOutlined />}
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Send
                                    </Button>):
                                    (<Button type="primary" loading iconPosition='start'>
                                        Sending
                                    </Button>)}
                                </Form.Item>
                            </div>
                        </div>
                        </Form>
                    <div className="flex form-control text-center mt-6">
                        <Link to="/login" className="text-sm text-[#50d71e]">Login</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;