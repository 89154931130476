import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const storedAuthState = localStorage.getItem('isAuthenticated') === 'true';
        setIsAuthenticated(storedAuthState);
    }, []);

    // Make the context object:
    const resourceContext = {
        isAuthenticated, setIsAuthenticated
    };

    return (
        <AuthContext.Provider value={resourceContext}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    isAuthenticated: PropTypes.bool
};

AuthProvider.defaultProps = {
    isAuthenticated: false,
};
