import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const InvoiceContext = createContext();

export const InvoiceProvider = ({ children }) => {
    const [currentTab, setCurrentTab] = useState('current');

    // Make the context object:
    const resourceContext = {
        currentTab, setCurrentTab
    };

    return (
        <InvoiceContext.Provider value={resourceContext}>
            {children}
        </InvoiceContext.Provider>
    );
};

InvoiceProvider.propTypes = {
    currentTab: PropTypes.bool
};

InvoiceProvider.defaultProps = {
    currentTab: false,
};
