import React, { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./state";

const RequireAuth = ({ children }) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
      if(localStorage.getItem('isAuthenticated')) {
        setIsAuthenticated(true);
          navigate(location.pathname);
      }
    }, []);

    if (!isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

  return children;
};
  

export default RequireAuth;