import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { SendOutlined} from '@ant-design/icons';
import { ResetPasswordMutation } from "../services/mutations";
import { successToast, errorToast, formatError } from '../components/utils';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorPassword, setErrorPassword] = useState('')
    const [errorNewPassword, setErrorNewPassword] = useState('')
    const [apiError, setApiError] = useState('');
    const [form] = Form.useForm();
    const params = useParams();
    const location = useLocation();
    const url_data = location.search.split('=')
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setApiError('');
        setLoading(true);
        axios.post(API_URL, {
            query: ResetPasswordMutation,
            variables: {
                "newPassword": password, 
                "token": url_data[1]
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
         })
        .then((response) => {
            console.log(response.data)
            successToast('Password reset successfull');
            handleGotoLogin();
        })
        .catch((error) => {
            setLoading(false);
            setLoading(false);
            setError(true);
            setApiError('Error occurred during password reset');
            errorToast(apiError)
            console.error('Error:', error);
        });
    };

    const handleGotoLogin = () => {
        setLoading(false);
        return navigate('/login');
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleChangeNewPassword = (e) => {
        setNewPassword(e.target.value);
    };
    return(
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="gap-5">
                <h1 className="text-3xl font-bold p-10">Reset Password</h1>
            </div>
            <div className="card bg-base-100 rounded-md w-full max-w-sm shrink-0 shadow-2xl">
                <div className="card-body p-10">
                    <Form 
                        form={form}
                        layout="vertical" 
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password 
                                className="grow" 
                                placeholder="****" 
                                onChange={(e) => handleChangePassword(e)} 
                            />
                        </Form.Item>
                        {(error)?<div className="text-center text-error">{errorPassword}</div>: null}
                        <Form.Item
                        label="Confirm Password"
                        name="new-password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                validator: (rule, value) => {
                                    if (value!== newPassword) {
                                        return Promise.reject('Passwords do not match');
                                    }
                                    return Promise.resolve();
                                },
                                message: 'Passwords do not match!',
                            }
                        ]}
                        >
                            <Input.Password 
                                className="grow" 
                                placeholder="****" 
                                onChange={(e) => handleChangeNewPassword(e)} 
                            />
                        </Form.Item>
                        {(error)?<div className="text-center text-error">{errorPassword}</div>: null}
                        <div className="flex items-end">
                            <div className="form-control">
                                <Form.Item style={{ padding: 0, margin: 0}}>
                                    {(loading === false)?
                                    (
                                        <Button 
                                        
                                        type="primary" 
                                        htmlType="submit" 
                                        icon={<SendOutlined />}
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Reset
                                    </Button>):
                                    (<Button type="primary" loading iconPosition='start'>
                                        Resetting
                                    </Button>)}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {/* <form>
                <input type="text" placeholder="New Password" />
                <input type="text" placeholder="Confirm New Password" />
                <button type="submit">Reset Password</button>
            </form> */}
        </div>

    )
}

export default ResetPassword;