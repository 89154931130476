import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
};

export const yearMonths = [
    'Jan', 
    'Feb', 
    'Mar', 
    'Apr', 
    'May', 
    'Jun', 
    'Jul', 
    'Aug', 
    'Sep', 
    'Oct', 
    'Nov', 
    'Dec'
];

export const getYearDifference = (date1, date2) => {
    // Convert the dates to Date objects
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Calculate the difference in years
    const yearDifference = d2.getFullYear() - d1.getFullYear();

    // Adjust for the months and days to get a more accurate year difference
    const monthDifference = d2.getMonth() - d1.getMonth();
    const dayDifference = d2.getDate() - d1.getDate();

    // If the month or day difference is negative, subtract one year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        return yearDifference - 1;
    }

    return yearDifference;
}

export const compareDates = (date1, date2) => {
    // Convert the input strings to Date objects
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Calculate the difference in years
    const yearsDifference = Math.abs(d2.getFullYear() - d1.getFullYear());

    // Create an array of years between the two dates
    const yearsArray = [];
    const startYear = Math.min(d1.getFullYear(), d2.getFullYear());
    const endYear = Math.max(d1.getFullYear(), d2.getFullYear());

    for (let year = startYear; year <= endYear; year++) {
        yearsArray.push(year);
    }

    return {
        yearsDifference: yearsDifference,
        yearsArray: yearsArray
    };
}