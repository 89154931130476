export const isValidEmail = (email) => {
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email_pattern.test(email);
}

export const encrypt = (data) => {
    if (typeof data === 'number') {
        return btoa(data.toString());
    } else if (typeof data === 'string') {
        return btoa(data);
    } else {
        throw new Error('Unsupported data type');
    }
}

// Function to decrypt a string or number
export const decrypt = (data) => {
    const decoded = atob(data);
    if (!isNaN(decoded)) {
        return Number(decoded);
    } else {
        return decoded;
    }
}